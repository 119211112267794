import { formFieldEvent } from '@octagon/public-analytics';
import { ACCOUNT_TYPE, AEM_CONFIG, AEM_CONTENT_URLS, AEM_LOGOUT_PATH, PROD_AUTH_URL_DOMAIN, QA_AUTH_URL_DOMAIN, PROD_LOGIN_URL, PROD_LOGOUT_URL, PROTECTED_URLS, QA_LOGIN_URL, QA_LOGOUT_URL, AEM_PAYMENT_PATH, PAYMENT_PATH, NAV_KEYS, PAYMENT_PATH_LANDING_AFTER_AUTH, STATUS_NAMES, UNTIMED_MODAL, MODAL_NAME, FORM_NAME, PRELOADING_IMAGES, } from '../constants/constants';
import { getLoggedInStatus } from "./UserLoggedInDetails";
export const getLatestExpiryLeaseDate = (accounts) => {
    const leaseAccountData = accounts.reduce((accumulator, account) => {
        if (account?.accountTypeName === 'LEASE' &&
            account?.maturityDate &&
            calculateDateIsLessThan180Days(account.maturityDate)) {
            accumulator.push(account);
        }
        return sortAccountsByMaturityDate(accumulator);
    }, []);
    if (leaseAccountData) {
        return leaseAccountData[0];
    }
};
export const calculateDateIsLessThan180Days = (maturityDate) => {
    const currentDate = new Date();
    const expiryDate = new Date(maturityDate);
    const diffTime = expiryDate.getTime() - currentDate.getTime();
    const diffDays = diffTime / (1000 * 3600 * 24);
    return diffDays < 180;
};
export const sortAccountsByMaturityDate = (accounts) => {
    return accounts.sort((a, b) => {
        // @ts-ignore
        return new Date(a.maturityDate) - new Date(b.maturityDate);
    });
};
export const displayDateInHumanReadableFormat = (date) => {
    if (date) {
        const dateArray = date.split('-');
        const month = [
            '',
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return `${month[Number(dateArray[1])]} ${dateArray[2]}, ${dateArray[0]}`;
    }
    else {
        return 'N/A';
    }
};
export const maskAccountNumber = (accountNumber) => {
    return accountNumber.replace(/.(?=.{8})/g, '*');
};
export const convertAmountToUSDFormat = (amount) => {
    return amount ? amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    }) : "$0.00";
};
export const convertAmountFormat = (amount) => {
    return amount
        ? amount.toLocaleString()
        : '0.00';
};
export const sumOfAccountBalances = (...amount) => {
    return amount.reduce((acc, val) => acc + val, 0);
};
export const getQuickLinksContent = (className, label, data, vertical, displayNumbers, showModalAlert) => {
    return {
        className: className,
        label: label,
        vertical: vertical,
        prefixIcon: '',
        displayNumbers: displayNumbers,
        data: data,
        showModalAlert: showModalAlert,
    };
};
export const getBellyBandsIcons = (content, key, nestedKey) => {
    let iconInfo = {
        text: '',
        url: '',
        link: '',
        id: '',
        navigationKeyId: '',
        isExtLink: false,
        analytics: '',
    };
    return Object.values(content[key][nestedKey]).reduce((accumulator, icon) => {
        iconInfo = {
            text: icon.link.value,
            url: AEM_CONFIG.IMAGES_BASE_URL_QA2 + icon.image.source,
            link: icon.link.url,
            id: icon.id,
            navigationKeyId: icon.link.navigationKeyId,
            isExtLink: icon.link.showSpeedBump ? icon.link.showSpeedBump : false,
            analytics: icon.link.trackingId,
        };
        accumulator.push(iconInfo);
        return accumulator;
    }, []);
};
export const getEnvironment = () => {
    const url = window.location.href;
    const devPattern = /dev/i;
    const localPattern = /localhost/i;
    const testPattern = /((uat)|(qa)|(test))/i;
    return {
        isLocal: localPattern.test(url),
        isDev: devPattern.test(url),
        isTest: testPattern.test(url),
        isProd: !localPattern.test(url) &&
            !devPattern.test(url) &&
            !testPattern.test(url),
    };
};
export const getAEMBackendUrls = () => {
    return getEnvironment().isProd
        ? AEM_CONTENT_URLS.PROD
        : AEM_CONTENT_URLS.NON_PROD;
};
export const trimFirstCharacterIfSlash = (url) => {
    return url.startsWith('/') ? url.slice(1) : url;
};
export const buildProtectedURL = (url) => {
    const isUserLoggedIn = getLoggedInStatus();
    let completeUrl;
    const env = getEnvironment();
    const domain = env.isProd ? PROD_AUTH_URL_DOMAIN : QA_AUTH_URL_DOMAIN;
    if (url === AEM_LOGOUT_PATH) {
        completeUrl = env.isProd ? PROD_LOGOUT_URL : QA_LOGOUT_URL;
    }
    else if (url === AEM_PAYMENT_PATH) {
        completeUrl = isUserLoggedIn ? `${domain}${PAYMENT_PATH}` : `${domain}${NAV_KEYS}${trimFirstCharacterIfSlash(PAYMENT_PATH_LANDING_AFTER_AUTH)}`;
    }
    else if (env.isLocal || isUserLoggedIn) {
        completeUrl = url;
    }
    else if (PROTECTED_URLS.includes(url)) {
        completeUrl = env.isProd ? `${PROD_LOGIN_URL}${trimFirstCharacterIfSlash(url)}` : `${QA_LOGIN_URL}${trimFirstCharacterIfSlash(url)}`;
    }
    else {
        completeUrl = url;
    }
    return completeUrl;
};
export const lifeCycleStatus = (account) => {
    const lifeCycleStatus = {
        isLoanStart: false,
        isLoanMid: false,
        isLoanEnd: false,
        isLeaseStart: false,
        isLeaseMid: false,
        isLeaseEnd: false,
    };
    if (account) {
        const { accountTypeName, lifecycleStatusName, remainingPaymentCount, } = account;
        const convertedLifecycleStatusName = lifecycleStatusName ? lifecycleStatusName.toLowerCase() : '';
        if (accountTypeName === ACCOUNT_TYPE.LOAN) {
            lifeCycleStatus.isLoanStart =
                convertedLifecycleStatusName.toLowerCase() === STATUS_NAMES.START;
            lifeCycleStatus.isLoanMid =
                convertedLifecycleStatusName.toLowerCase() === STATUS_NAMES.MID;
            lifeCycleStatus.isLoanEnd =
                (convertedLifecycleStatusName.toLowerCase() !== STATUS_NAMES.MID &&
                    convertedLifecycleStatusName.toLowerCase() !== STATUS_NAMES.START) ||
                    remainingPaymentCount <= 3;
        }
        else if (accountTypeName === ACCOUNT_TYPE.LEASE) {
            lifeCycleStatus.isLeaseStart =
                convertedLifecycleStatusName.toLowerCase() === STATUS_NAMES.START;
            lifeCycleStatus.isLeaseMid =
                convertedLifecycleStatusName.toLowerCase() === STATUS_NAMES.MID;
            lifeCycleStatus.isLeaseEnd =
                convertedLifecycleStatusName.toLowerCase() !== STATUS_NAMES.MID &&
                    convertedLifecycleStatusName.toLowerCase() !== STATUS_NAMES.START;
        }
    }
    return lifeCycleStatus;
};
export const getSearchOriginalContent = (input, search) => {
    const regEx = new RegExp(`(${search})`, "gi");
    return input.split(regEx).find(x => x.toLowerCase() === search.toLowerCase());
};
export const customStringReplace = (input, searchMask, replaceMask) => {
    const regEx = new RegExp(searchMask, "gi");
    return input.replaceAll(regEx, replaceMask);
};
export const stringSplitReplace = (input, searchMask, replaceMask) => {
    if (!searchMask.includes(' ')) {
        const regEx = new RegExp(searchMask, "gi");
        const inpSplit = input.split(' ').map(x => x.lastIndexOf('}}') === -1 ? x.replace(regEx, replaceMask) : x);
        return inpSplit.join(' ');
    }
    else {
        return customStringReplace(input, searchMask, replaceMask);
    }
};
export const regexIndexOf = (str, searchMask) => {
    const regEx = new RegExp(searchMask, "gi");
    const match = str.match(regEx);
    return match ? str.indexOf(match[0]) : -1;
};
export const convertDateStringIntoHumanReadableFormat = (date) => {
    if (date && date.length === 9) {
        // Define a map for month abbreviations to full month names
        const monthMap = {
            JAN: "January",
            FEB: "February",
            MAR: "March",
            APR: "April",
            MAY: "May",
            JUN: "June",
            JUL: "July",
            AUG: "August",
            SEP: "September",
            OCT: "October",
            NOV: "November",
            DEC: "December"
        };
        // Extract the day, month abbreviation, and year from the string
        const day = parseInt(date.slice(0, 2), 10);
        const monthAbbr = date.slice(2, 5);
        const year = parseInt(date.slice(5), 10);
        // Get the full month name from the month abbreviationy
        const monthName = monthMap[monthAbbr.toUpperCase()];
        // Return the formatted date string
        return `${monthName} ${day}, ${year}`;
    }
    else {
        return '';
    }
};
export const getSearchCount = (str, search) => {
    const regEx = new RegExp(search, "gi");
    const strSplit = str.split(regEx);
    return strSplit.length ? strSplit.length - 1 : 0;
};
export const updateChaseDomainBasedOnEnv = (url) => {
    if (!getEnvironment().isProd && url?.includes(PROD_AUTH_URL_DOMAIN)) {
        return url.replace(PROD_AUTH_URL_DOMAIN, QA_AUTH_URL_DOMAIN);
    }
    return url;
};
export const getModalAnalyticsIds = (navigationKey, modalDescription) => {
    const modalName = navigationKey === UNTIMED_MODAL
        ? MODAL_NAME.EXTERNAL_SPEED_BUMP
        : MODAL_NAME.INTERNAL_SPEED_BUMP;
    return navigationKey ? `${modalName},${modalDescription ?? ''}` : modalDescription;
};
export const formAnalyticsTrigger = (trackingId, pageName) => {
    formFieldEvent({
        formStep: trackingId,
        formName: FORM_NAME[pageName],
    });
};
export function createPreloadImageTags() {
    const urls = PRELOADING_IMAGES.preloadImageurls;
    urls.map((imageLink) => {
        const link = document.createElement("LINK");
        link.setAttribute("rel", "preload");
        link.setAttribute("type", "image/webp/jpeg");
        link.setAttribute("href", AEM_CONFIG.IMAGES_BASE_URL_QA2 + imageLink);
        document.head.appendChild(link);
    });
}
